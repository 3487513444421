/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/DashboardNavbar';
import Footer from 'examples/Footer';
import { DateTimePicker } from '@mui/x-date-pickers';
import { pickersLayoutClasses } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
// import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart';
import {
  // getGameSessions,
  // getChartAmount,
  // getStatistics,
  getEmailSummary
  // ggrForPeriod,
  // ggrForPeriodAverage,
  // getCountryChart
} from 'services/analytics';
import { useEffect, useState } from 'react';
import { Card, colors, Skeleton } from '@mui/material';
import HorizontalBarChart from 'examples/Charts/BarCharts/HorizontalBarChart';
// import { Chart } from 'react-google-charts';
import { CardGiftcard } from '@mui/icons-material';
import PieChart from 'examples/Charts/PieChart';
import EmailMetricRow from './EmailMetricRow';

function Dashboard() {
  // const [gameWins, setGameWins] = useState([]);
  // const [gameLoses, setGameLoses] = useState([]);
  // const [correctMonths, setCorrectMonths] = useState([]);
  // const [newRegistrations, setNewRegistrations] = useState([]);
  // const [gameSessions, setGameSessions] = useState([]);
  // const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // const [statistics, setStatistics] = useState([]);
  // const [profit, setProfit] = useState(0);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [summaryFrom, setSummaryFrom] = useState();
  const [summaryTo, setSummaryTo] = useState();
  const [summary, setSummary] = useState({gameData: {}});

  // const [fromGGR, setFromGGR] = useState(null);
  // const [toGGR, setToGGR] = useState(null);
  // const [ggr, setGGR] = useState(null);
  // const [average, setAverage] = useState(null);

  // const [fromChart, setFromChart] = useState(null);
  // const [toChart, setToChart] = useState(null);
  // const [countries, setCountries] = useState([]);
  // const [countryCounts, setCountryCounts] = useState([]);

  const handleFromChange = (date, setterFrom, setterTo) => {
    if (to) {
      if (date > to) {
        setterFrom(to);
        setterTo(date);
      } else {
        setterFrom(date);
      }
    } else {
      setterFrom(new Date(date));
    }
  };
  const handleToChange = (date, setterFrom, setterTo) => {
    if (from) {
      if (date < from) {
        setterFrom(date);
        setterTo(from);
      } else {
        setterTo(date);
      }
    } else {
      setterTo(new Date(date));
    }
  };

  // const handleFromChangeGGR = (date, setterFrom, setterTo) => {
  //   if (toGGR) {
  //     if (date > toGGR) {
  //       setterFrom(toGGR);
  //       setterTo(date);
  //     } else {
  //       setterFrom(date);
  //     }
  //   } else {
  //     setterFrom(new Date(date));
  //   }
  // };

  // const handleToChangeGGR = (date, setterFrom, setterTo) => {
  //   if (fromGGR) {
  //     if (date < fromGGR) {
  //       setterFrom(date);
  //       setterTo(fromGGR);
  //     } else {
  //       setterTo(date);
  //     }
  //   } else {
  //     setterTo(new Date(date));
  //   }
  // };

  // const handleFromChangeChart = (date, setterFrom, setterTo) => {
  //   if (toChart) {
  //     if (date > toChart) {
  //       setterFrom(toChart);
  //       setterTo(date);
  //     } else {
  //       setterFrom(date);
  //     }
  //   } else {
  //     setterFrom(new Date(date));
  //   }
  // };

  // const handleToChangeChart = (date, setterFrom, setterTo) => {
  //   if (fromChart) {
  //     if (date < fromChart) {
  //       setterFrom(date);
  //       setterTo(fromChart);
  //     } else {
  //       setterTo(date);
  //     }
  //   } else {
  //     setterTo(new Date(date));
  //   }
  // };

  // useEffect(() => {
  //   getStatistics(from, to).then((res) => setStatistics(res.data));
  // }, [from, to]);

  useEffect(() => {
    async function fetchData() {
      const res = await getEmailSummary(summaryFrom, summaryTo);
      setSummary(res.data);
    }
    fetchData();
  }, [summaryFrom, summaryTo]);

  // useEffect(() => {
  //   ggrForPeriod(fromGGR, toGGR).then((res) => setGGR(res?.data));
  // }, [fromGGR, toGGR]);

  // const gameData = {
  //   labels: correctMonths,
  //   datasets: [
  //     {
  //       label: 'Wins',
  //       data: gameWins,
  //       color: 'info'
  //     },
  //     {
  //       label: 'Losses',
  //       data: gameLoses
  //     }
  //   ]
  // };

  // const sessionsData = {
  //   labels: ['Daily bets'],
  //   datasets: [
  //     {
  //       label: 'Bets',
  //       data: gameSessions,
  //       color: 'info'
  //     }
  //   ]
  // };

  // useEffect(() => {
  //   getChartAmount('win').then((res) => {
  //     let wins = res.map((m) => {
  //       m[0] === 12
  //         ? setCorrectMonths((prev) => [...prev, months[0]])
  //         : setCorrectMonths((prev) => [...prev, months[m[0]]]);
  //       return m[1];
  //     });
  //     setGameWins(wins);
  //   });
  //   getChartAmount('lose').then((res) => {
  //     let loses = res.map((m) => {
  //       return m[1];
  //     });
  //     setGameLoses(loses);
  //   });
  // }, []);

  // useEffect(() => {
  //   getGameSessions().then((res) => setGameSessions(res));
  //   getStatistics().then((res) => setStatistics(res.data));
  // }, []);

  // useEffect(() => {
  //   let countProfit = 0;
  //   statistics?.profitForPeriod?.map((profit) => {
  //     countProfit = Number(countProfit) + Number(profit.profit);
  //   });
  //   setProfit(countProfit.toFixed(2));
  // }, [statistics]);

  // useEffect(() => {
  //   ggrForPeriodAverage(fromGGR, toGGR).then((res) => setAverage(res.data));
  // }, [fromGGR, toGGR]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await getCountryChart(fromChart, toChart);

  //       // Initialize temporary arrays for countries and country counts
  //       const countriesArray = [];
  //       const countryCountsArray = [];

  //       if (Array.isArray(res.data)) {
  //         res.data.forEach((e) => {
  //           countriesArray.push(e.country);
  //           countryCountsArray.push(Number(e.country_count));
  //         });
  //       } else if (res.data) {
  //         countriesArray.push(res.data.country);
  //         countryCountsArray.push(Number(res.data.country_count));
  //       }

  //       // Set state for countries and country counts
  //       setCountries(countriesArray);
  //       setCountryCounts(countryCountsArray);
  //     } catch (error) {
  //       console.error('Error fetching chart data:', error);
  //     }
  //   };

  //   fetchData();
  // }, [fromChart, toChart]);
  // const options = {
  //   title: 'Player countries'
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={5}>
          <Grid container spacing={3} direction='row' justify='center' alignItems='stretch'>
            {/* <Grid item xs={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {gameWins.length > 0 ? (
                    <GradientLineChart
                      title='Game Wins vs. Losses ($)'
                      description='Monthly performance'
                      chart={gameData}
                      tension={0.5}
                    />
                  ) : (
                    <Skeleton height={400} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {gameSessions.length > 0 ? (
                    <HorizontalBarChart title='Daily bets' description='24 Hours performance' chart={sessionsData} />
                  ) : (
                    <Skeleton height={400} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}
                      borderRadius='lg'
                      p={3}
                      mb={2}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label='From'
                          ampmInClock={false}
                          closeOnSelect={true}
                          ampm={false}
                          showDaysOutsideCurrentMonth
                          value={fromChart}
                          viewRenderers={{
                            hours: null,
                            minutes: null,
                            seconds: null
                          }}
                          onChange={(e) => {
                            handleFromChangeChart(e.$d, setFromChart, setToChart);
                          }}
                          slotProps={{
                            layout: {
                              sx: {
                                [`.${pickersLayoutClasses.actionBar}`]: {
                                  display: 'none'
                                }
                              }
                            }
                          }}
                        />
                        <DateTimePicker
                          label='To'
                          ampmInClock={false}
                          closeOnSelect={true}
                          ampm={false}
                          showDaysOutsideCurrentMonth
                          value={toChart}
                          viewRenderers={{
                            hours: null,
                            minutes: null,
                            seconds: null
                          }}
                          onChange={(e) => {
                            handleToChangeChart(e.$d, setFromChart, setToChart);
                          }}
                          slotProps={{
                            layout: {
                              sx: {
                                [`.${pickersLayoutClasses.actionBar}`]: {
                                  display: 'none'
                                }
                              }
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </MDBox>
                    <MDBox sx={{ display: 'flex', justifyContent: 'center' }}>
                      <MDTypography fontSize='13px'>
                        {fromChart || toChart
                          ? `Showing results from ${
                              fromChart ? new Date(fromChart).toLocaleDateString() : 'the beggining'
                            } to ${toChart ? new Date(toChart).toLocaleDateString() : 'now'}`
                          : 'Showing results for all the time'}{' '}
                      </MDTypography>
                    </MDBox>
                    <PieChart
                      // icon={{ color: 'info', component: 'leaderboard' }}
                      title='Player countries'
                      description='Analytics Insights'
                      chart={{
                        labels: countries,
                        datasets: {
                          label: 'Projects',
                          backgroundColors: ['info', 'primary', 'dark', 'secondary', 'primary'],
                          data: countryCounts
                        },
                        options: {
                          plugins: {
                            legend: {
                              display: true
                            }
                          }
                        }
                      }}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Grid> */}
            {/* <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox position='relative' borderRadius='lg' p={3}>
                      <MDBox mb={1}>
                        <MDTypography
                          variant='h5'
                          fontWeight='bold'
                          textTransform='capitalize'
                          fontSize='1.2rem'
                          mb={2}
                        >
                          Statistics
                        </MDTypography>
                        <MDBox sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }} mb={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label='From'
                              ampmInClock={false}
                              closeOnSelect={true}
                              ampm={false}
                              showDaysOutsideCurrentMonth
                              value={from}
                              viewRenderers={{
                                hours: null,
                                minutes: null,
                                seconds: null
                              }}
                              onChange={(e) => {
                                handleFromChange(e.$d, setFrom, setTo);
                              }}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${pickersLayoutClasses.actionBar}`]: {
                                      display: 'none'
                                    }
                                  }
                                }
                              }}
                            />
                            <DateTimePicker
                              label='To'
                              ampmInClock={false}
                              closeOnSelect={true}
                              ampm={false}
                              showDaysOutsideCurrentMonth
                              value={to}
                              viewRenderers={{
                                hours: null,
                                minutes: null,
                                seconds: null
                              }}
                              onChange={(e) => {
                                handleToChange(e.$d, setFrom, setTo);
                              }}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${pickersLayoutClasses.actionBar}`]: {
                                      display: 'none'
                                    }
                                  }
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </MDBox>
                        <MDTypography fontSize='13px'>
                          {from || to
                            ? `Showing results from ${
                                from ? new Date(from).toLocaleDateString() : 'the beggining'
                              } to ${to ? new Date(to).toLocaleDateString() : 'now'}`
                            : 'Showing results for all the time'}{' '}
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Bets:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${statistics?.betsForPeriod ? statistics?.betsForPeriod : 0}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Bets (Amount):
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${
                              statistics?.betsForPeriodAmount?.length > 0
                                ? statistics?.betsForPeriodAmount[0]?.amount
                                  ? statistics?.betsForPeriodAmount[0]?.amount
                                  : 0
                                : statistics?.betsForPeriodAmount?.amount
                            }`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Non-rollbacked bets:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${statistics?.nonRollbackedBetsForPeriod ? statistics?.nonRollbackedBetsForPeriod : 0}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Non-rollbacked bets (Amount):
                          </MDTypography>

                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${
                              statistics?.nonRollbackedBetsForPeriodAmount?.length > 0
                                ? statistics?.nonRollbackedBetsForPeriodAmount[0]?.amount
                                  ? statistics?.nonRollbackedBetsForPeriodAmount[0]?.amount
                                  : 0
                                : statistics?.nonRollbackedBetsForPeriodAmount?.amount
                            }`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Profit:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${profit}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid> */}
            <Grid item xs={6}>
              <Card>
                <MDBox position='relative' borderRadius='lg' p={3}>
                  <MDBox mb={1}>
                    <MDTypography variant='h5' fontWeight='bold' textTransform='capitalize' fontSize='1.2rem' mb={2}>
                      Email Metric Summary
                    </MDTypography>
                    <MDBox sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }} mb={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label='From'
                          ampmInClock={false}
                          closeOnSelect={true}
                          ampm={false}
                          showDaysOutsideCurrentMonth
                          value={summaryFrom}
                          viewRenderers={{
                            hours: null,
                            minutes: null,
                            seconds: null
                          }}
                          onChange={(e) => {
                            handleFromChange(new Date(e.$d).toDateString(), setSummaryFrom, setSummaryTo);
                          }}
                          slotProps={{
                            layout: {
                              sx: {
                                [`.${pickersLayoutClasses.actionBar}`]: {
                                  display: 'none'
                                }
                              }
                            }
                          }}
                        />
                        <DateTimePicker
                          label='To'
                          ampmInClock={false}
                          closeOnSelect={true}
                          ampm={false}
                          showDaysOutsideCurrentMonth
                          value={summaryTo}
                          viewRenderers={{
                            hours: null,
                            minutes: null,
                            seconds: null
                          }}
                          onChange={(e) => {
                            handleToChange(new Date(e.$d).toDateString(), setSummaryFrom, setSummaryTo);
                          }}
                          slotProps={{
                            layout: {
                              sx: {
                                [`.${pickersLayoutClasses.actionBar}`]: {
                                  display: 'none'
                                }
                              }
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </MDBox>
                    <MDTypography fontSize='13px'>
                      {summaryFrom || summaryTo
                        ? `Showing results from ${
                            summaryFrom ? new Date(summaryFrom).toLocaleDateString() : 'the beggining'
                          } to ${summaryTo ? new Date(summaryTo).toLocaleDateString() : 'now'}`
                        : 'Showing results for all the time'}{' '}
                    </MDTypography>
                  </MDBox>
                  {Object.entries(summary.gameData)?.map((game, index) => {
                    return (
                      <MDBox
                        key={index}
                        mb={index + 1 !== Object?.entries(summary).length && 2}
                        sx={{ border: '0.3px solid white', borderRadius: '12px', padding: '15px' }}
                      >
                        <MDTypography
                          sx={{
                            fontWeight: 800,
                            borderBottom: '1px solid white',
                            width: '100%'
                          }}
                        >
                          {game[0]}
                        </MDTypography>
                        <MDBox mt={2}>
                          <Grid container justifyContent={'space-between'}>
                            <Grid>Coins:</Grid>
                            <Grid>Gems:</Grid>
                          </Grid>
                          <EmailMetricRow title='PnL' coins={game[1].coins.PnL} gems={game[1].gems?.PnL} />
                        </MDBox>
                        <EmailMetricRow title='Bet Average' coins={game[1].coins.betAverage} gems={game[1].gems?.betAverage} />
                        <EmailMetricRow title='Bet Count' coins={game[1].coins.betCount} gems={game[1].gems?.betCount} />
                        <EmailMetricRow title='Bet Sum' coins={game[1].coins.betSum} gems={game[1].gems?.betSum} />
                        <EmailMetricRow title='Lose Average' coins={game[1].coins.loseAverage} gems={game[1].gems?.loseAverage} />
                        <EmailMetricRow title='Lose Count' coins={game[1].coins.loseCount} gems={game[1].gems?.loseCount} />
                        <EmailMetricRow title='Lose Sum' coins={game[1].coins.loseSum} gems={game[1].gems?.loseSum} />
                        <EmailMetricRow title='Player Count' coins={game[1].coins.playerCount} gems={game[1].gems?.playerCount} />
                        <EmailMetricRow title='Rake Average' coins={game[1].coins.rakeAverage} gems={game[1].gems?.rakeAverage} />
                        <EmailMetricRow title='Rake Count' coins={game[1].coins.rakeCount} gems={game[1].gems?.rakeCount} />
                        <EmailMetricRow title='Rake Sum' coins={game[1].coins.rakeSum} gems={game[1].gems?.rakeSum} />
                        <EmailMetricRow title='Win Average' coins={game[1].coins.winAverage} gems={game[1].gems?.winAverage} />
                        <EmailMetricRow title='Win Count' coins={game[1].coins.winCount} gems={game[1].gems?.winCount} />
                        <EmailMetricRow title='Win Sum' coins={game[1].coins.winSum} gems={game[1].gems?.winSum} />
                      </MDBox>
                    );
                  })}
                </MDBox>
              </Card>
            </Grid>
            {/* <Grid item xs={12}>
                  <Card>
                    <MDBox position='relative' borderRadius='lg' p={3}>
                      <MDBox mb={1}>
                        <MDTypography
                          variant='h5'
                          fontWeight='bold'
                          textTransform='capitalize'
                          fontSize='1.2rem'
                          mb={2}
                        >
                          GGR & Average Metrics
                        </MDTypography>
                        <MDBox sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }} mb={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label='From'
                              ampmInClock={false}
                              closeOnSelect={true}
                              ampm={false}
                              showDaysOutsideCurrentMonth
                              value={fromGGR}
                              viewRenderers={{
                                hours: null,
                                minutes: null,
                                seconds: null
                              }}
                              onChange={(e) => {
                                handleFromChangeGGR(e.$d, setFromGGR, setToGGR);
                              }}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${pickersLayoutClasses.actionBar}`]: {
                                      display: 'none'
                                    }
                                  }
                                }
                              }}
                            />
                            <DateTimePicker
                              label='To'
                              ampmInClock={false}
                              closeOnSelect={true}
                              ampm={false}
                              showDaysOutsideCurrentMonth
                              value={toGGR}
                              viewRenderers={{
                                hours: null,
                                minutes: null,
                                seconds: null
                              }}
                              onChange={(e) => {
                                handleToChangeGGR(e.$d, setFromGGR, setToGGR);
                              }}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${pickersLayoutClasses.actionBar}`]: {
                                      display: 'none'
                                    }
                                  }
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </MDBox>
                        <MDTypography fontSize='13px'>
                          {fromGGR || toGGR
                            ? `Showing results from ${
                                fromGGR ? new Date(fromGGR).toLocaleDateString() : 'the beggining'
                              } to ${toGGR ? new Date(toGGR).toLocaleDateString() : 'now'}`
                            : 'Showing results for all the time'}{' '}
                        </MDTypography>
                      </MDBox>
                      <MDBox style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MDBox sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            GGR:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            <Grid container style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                              {Array.isArray(ggr) ? (
                                ggr?.map((e, index) => (
                                  <Grid key={index}>
                                    <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                      {e?.client}
                                    </MDTypography>
                                    :{' '}
                                    <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                      {e?.ggr}
                                    </MDTypography>
                                  </Grid>
                                ))
                              ) : (
                                <Grid>
                                  <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                    {ggr?.client}
                                  </MDTypography>
                                  :{' '}
                                  <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                    {ggr?.ggr}
                                  </MDTypography>
                                </Grid>
                              )}
                            </Grid>
                          </MDTypography>
                        </MDBox>
                        <MDBox sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Average Metrics:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            <Grid container style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                              {Array.isArray(average) ? (
                                average.map((e, index) => (
                                  <Grid
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '5px',
                                      paddingBottom: '10px'
                                    }}
                                    key={index}
                                  >
                                    <Grid>
                                      <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                        client:{' '}
                                      </MDTypography>
                                      <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                        {e?.client}
                                      </MDTypography>
                                    </Grid>
                                    <Grid>
                                      <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                        bet:{' '}
                                      </MDTypography>
                                      <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                        {e?.bet.toFixed(2)}
                                      </MDTypography>
                                    </Grid>
                                    <Grid>
                                      <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                        rake:{' '}
                                      </MDTypography>
                                      <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                        {e?.rake.toFixed(2)}
                                      </MDTypography>
                                    </Grid>
                                    <Grid>
                                      <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                        win:{' '}
                                      </MDTypography>
                                      <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                        {e?.win.toFixed(2)}
                                      </MDTypography>
                                    </Grid>
                                  </Grid>
                                ))
                              ) : (
                                <Grid
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '5px',
                                    paddingBottom: '10px'
                                  }}
                                >
                                  <Grid>
                                    <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                      client:{' '}
                                    </MDTypography>
                                    <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                      {average?.client}
                                    </MDTypography>
                                  </Grid>
                                  <Grid>
                                    <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                      bet:{' '}
                                    </MDTypography>
                                    <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                      {average?.bet}
                                    </MDTypography>
                                  </Grid>
                                  <Grid>
                                    <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                      rake:{' '}
                                    </MDTypography>
                                    <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                      {average?.rake}
                                    </MDTypography>
                                  </Grid>
                                  <Grid>
                                    <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                      win:{' '}
                                    </MDTypography>
                                    <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                      {average?.win}
                                    </MDTypography>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid> */}
            {/* </Grid>
            </Grid> */}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
