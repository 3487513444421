import dayjs from 'dayjs';
import useAxios from 'hooks/useAxios';

export const getEmailSummary = async (dateFrom = '', dateTo = '') => {
  try {
    const api = useAxios();

    if (dateFrom === '' || !dateFrom) {
      dateFrom = dayjs().date(1).month(1).year(2000);
      dateFrom = dateFrom.toJSON().split('T')[0];
    } else {
      dateFrom = new Date(dateFrom).toISOString().split('T')[0];
    }

    if (dateTo === '' || !dateTo) {
      dateTo = dayjs();
      dateTo = dateTo.toJSON().split('T')[0];
    } else {
      dateTo = new Date(dateTo).toISOString().split('T')[0];
    }

    const res = await api.get(`v1/metric/statistic-for-period`, {
      params: {
        startDate: dateFrom,
        endDate: dateTo
      }
    });

    return res;
  } catch (error) {
    console.log(error);
    return {
      data: []
    };
  }
};
