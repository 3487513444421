import useAxios from 'hooks/useAxios';

export async function removeBlacklistedCountries(countries) {
  const api = useAxios();
  const params = { countryCodes: countries };

  try {
    const res = await api.delete('blacklisted-countries', { params });
    return res;
  } catch (err) {
    return err;
  }
}
