import useAxios from 'hooks/useAxios';

export async function sendBlacklistedCountries(countries) {
  const api = useAxios();
  const params = { countryCodes: countries };

  try {
    const res = await api.post('blacklisted-countries', params);
    return res;
  } catch (err) {
    return err;
  }
}
