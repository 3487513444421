import useAxios from 'hooks/useAxios';

export async function getBlacklistedCountries() {
  const api = useAxios();

  try {
    const res = await api.get('blacklisted-countries/by-continent');
    return res;
  } catch (err) {
    return err;
  }
}
