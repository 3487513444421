import { Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

export default function EmailMetricRow({ title, coins, gems }) {
  return (
    <Grid container justifyContent={'space-between'}>
      <MDBox sx={{ display: 'flex', gap: '15px' }}>
        <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
          {title}:
        </MDTypography>
        {/* coins */}
        <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
          {` ${coins ? coins : 0}`}
        </MDTypography>
      </MDBox>
      {/* gems */}
      <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
        {` ${gems ? gems : 0}`}
      </MDTypography>
    </Grid>
  );
}
